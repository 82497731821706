import AuthService from '@/services/AuthService';
import utils from "@/helper/utils.js";

const initialState = {
  profile: null
}

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async initProfile({ commit }) {
      try {
        const resp = await AuthService.getMe()
        const profile = resp.data
        console.log('profile: ', profile)
        commit('setProfile', profile);
      } catch (e) {
        utils.handleHttpError(e, this);
      }
    },
    clearProfile({ commit }) {
      commit('setProfile', null);
    }
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile
    }
  }
};