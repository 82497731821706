import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/global.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

import i18n from './i18n'

import emitter from '@/helper/eventEmitter'

const app = createApp(App)
app.config.globalProperties.emitter = emitter

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app
  .use(i18n)
  .use(ElementPlus)
  .use(store)
  .use(router)
  .use(VCalendar, {})
  .use(VueTelInput)
  .component('QuillEditor', QuillEditor)
  .mount('#app')
