import http from '../http-common'

const BASE_API_URL = '/notification'

class NotificationService {
  getUnreadCount() {
    return http.get(`${BASE_API_URL}/getUnreadCount`)
  }
  getNotifications(page) {
    return http.get(`${BASE_API_URL}/getNotifications?page=${page}`)
  }
  updateLastReadAt() {
    return http.post(`${BASE_API_URL}/updateLastReadAt`)
  }
}

export default new NotificationService()
