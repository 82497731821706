import http from '../http-common'

const BASE_API_URL = '/auth'

class AuthService {
  login(data) {
    return http.post(`${BASE_API_URL}/signin`, data)
  }

  sendHelperSignUpEmail(data) {
    return http.post(`${BASE_API_URL}/sendHelperSignUpEmail`, data)
  }

  getEmailFromSignUpToken(data) {
    return http.post(`${BASE_API_URL}/getEmailFromSignUpToken`, data)
  }

  signUpHelper(data) {
    return http.post(`${BASE_API_URL}/signUpHelper`, data)
  }

  getMe() {
    return http.get(`${BASE_API_URL}/getMe`)
  }

  sendResetPasswordEmail(data) {
    return http.post(`${BASE_API_URL}/sendResetPasswordEmail`, data)
  }

  resetPassword(data) {
    return http.post(`${BASE_API_URL}/resetPassword`, data)
  }

  createOrUpdateGoogleRefreshTokenOfCenter(data) {
    return http.post(
      `${BASE_API_URL}/createOrUpdateGoogleRefreshTokenOfCenter`,
      data
    )
  }

  deleteGoogleRefreshTokenOfCenter() {
    return http.get(`${BASE_API_URL}/deleteGoogleRefreshTokenOfCenter`)
  }
}

export default new AuthService()
