import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { getUser, getEnv } from '@/helper/utils'
import { USER_LOCAL_STORAGE_KEY } from '@/helper/constants'

const axiosInstance = axios.create({
  baseURL: `${getEnv('BACKEND_DOMAIN')}/api`,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(config => {
  const user = getUser()

  config.headers['x-access-token'] = user?.accessToken;

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.response.status === 401) { // 401 indicates Unathenticated. i.e. no token or invalid token, or expired token
    localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
    store.dispatch('auth/clearProfile');
    router.push('/login');
  }

  return Promise.reject(error);
});

export default axiosInstance;