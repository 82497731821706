const _ = require('lodash')
const { DateTime } = require('luxon')
const { USER_LOCAL_STORAGE_KEY } = require('@/helper/constants')
const XLSX = require('xlsx')

exports.getEnv = (key) => {
  const suffix = process.env.VUE_APP_ENV_NAME
    ? `_${process.env.VUE_APP_ENV_NAME.toUpperCase()}`
    : ''

  return process.env[`VUE_APP_${key}${suffix}`]
}

exports.isSignedIn = () => !!localStorage.getItem(USER_LOCAL_STORAGE_KEY)

exports.isAdmin = () =>
  JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY)).role === 'ADMIN'

// Returns null if localStorage.getItem(USER_LOCAL_STORAGE_KEY) is null (i.e. USER_LOCAL_STORAGE_KEY not exists in localStorage)
exports.getUser = () => JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY))

exports.getTodayDateStr = () => {
  return DateTime.now().toFormat('yyyy-MM-dd')
}

// @param date e.g. 2020-01-05
exports.getHumanReadableDateStr = (date) => {
  if (!date) {
    return ''
  }

  const diffInDays = DateTime.fromISO(date)
    .diff(DateTime.now().startOf('day'), 'days')
    .toObject().days

  if (diffInDays === 0) {
    return '<b>Today</b>'
  } else if (diffInDays === 1) {
    return '<b>Tomorrow</b>'
  } else if (diffInDays === -1) {
    return '<b>Yesterday</b>'
  }

  if (Math.abs(diffInDays) <= 5) {
    return `<b>${Math.abs(diffInDays)}</b> d ${
      diffInDays > 0 ? 'later' : 'ago'
    }`
  }

  return DateTime.fromISO(date).toFormat('M/d/yy')
}

// @param date e.g. 2020-01-05
exports.displayDateInMdyy = (date) => {
  if (!date) {
    return ''
  }

  return DateTime.fromISO(date).toFormat('M/d/yy')
}

exports.getLevelName = (levelCode, isShortFormat = false) => {
  const searchResults = this.levelOptions.filter((o) => o.value === levelCode)
  if (searchResults.length === 0) {
    throw new Error(`Invalid levelCode ${levelCode}`)
  }

  if (isShortFormat) {
    if (levelCode === 900) {
      return 'H'
    } else if (levelCode === 1000) {
      return 'C'
    }
  }

  return searchResults[0].label
}

exports.getNextLevelCode = (levelCode) => {
  const index = _.findIndex(this.levelOptions, (o) => o.value === levelCode)
  if (index === -1) {
    throw new Error(`Invalid levelCode ${levelCode}`)
  }

  if (index === this.levelOptions.length - 1) {
    // Return null for maximum levelCode.
    return null
  }

  return this.levelOptions[index + 1].value
}

exports.getPersonName = (person) => {
  if (!person) {
    return ''
  }

  return person.firstName + (person.lastName ? ` ${person.lastName}` : '')
}

exports.handleHttpError = (e, _this) => {
  const msg =
    _.get(e, 'response.data.errors[0].message') || _.get(e, 'response.data')

  this.showErrorMessage(msg, _this)
  console.log(msg)
}

exports.showErrorMessage = (message, _this) => {
  _this.$message.error({ message, duration: 5000, showClose: true })
}

const getHourOptionLabel = (hourOptionVal) => {
  const label = `${hourOptionVal}h`
  // let label = `${Math.trunc(hourOptionVal)}h`

  // if (hourOptionVal % 1 === 0.5) {
  //   label += ' 30m'
  // }

  return label
}

const getHourOptions = () => {
  const nullOption = [
    {
      label: 'X',
      value: null,
    },
  ]

  return nullOption.concat(
    _.range(0.5, 12.5, 0.5).map((hourOptionVal) => ({
      label: getHourOptionLabel(hourOptionVal),
      value: hourOptionVal,
    }))
  )
}

exports.hourOptions = getHourOptions()

const getCycleOptions = () => {
  const nullOption = [
    {
      label: 'X',
      value: null,
    },
  ]

  return nullOption.concat(
    _.concat(
      _.range(0, 2, 0.25),
      _.range(2, 100, 1),
      _.range(100, 1010, 10)
    ).map((cycleOptionVal) => ({
      label: `${cycleOptionVal}c`,
      value: cycleOptionVal,
    }))
  )
}

exports.cycleOptions = getCycleOptions()

exports.levelOptions = [
  { label: '1', value: 100 },
  { label: '2', value: 200 },
  { label: '3', value: 300 },
  { label: '4', value: 400 },
  { label: '5', value: 500 },
  { label: '6', value: 600 },
  { label: '7', value: 700 },
  { label: 'Habit', value: 900 },
  { label: 'Comp', value: 1000 },
]

exports.genderOptions = [
  { label: 'Female', value: 'Female' },
  { label: 'Male', value: 'Male' },
]

exports.raceOptions = [
  {
    label: 'American Indian or Alaska Native',
    value: 'American Indian or Alaska Native',
  },
  { label: 'Asian', value: 'Asian' },
  { label: 'Black or African American', value: 'Black or African American' },
  { label: 'Hispanic or Latino', value: 'Hispanic or Latino' },
  { label: 'White', value: 'White' },
]

exports.jobCategoryOptions = [
  {
    label: 'Architecture and engineering',
    value: 'Architecture and engineering',
  },
  {
    label: 'Arts, culture and entertainment',
    value: 'Arts, culture and entertainment',
  },
  {
    label: 'Business, management and administration',
    value: 'Business, management and administration',
  },
  { label: 'Communications', value: 'Communications' },
  {
    label: 'Community and social services',
    value: 'Community and social services',
  },
  { label: 'Education', value: 'Education' },
  { label: 'Science and technology', value: 'Science and technology' },
  {
    label: 'Installation, repair and maintenance',
    value: 'Installation, repair and maintenance',
  },
  {
    label: 'Farming, fishing and forestry',
    value: 'Farming, fishing and forestry',
  },
  { label: 'Government', value: 'Government' },
  { label: 'Health and medicine', value: 'Health and medicine' },
  { label: 'Law and public policy', value: 'Law and public policy' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Student', value: 'Student' },
  { label: 'Retired', value: 'Retired' },
  { label: 'Other', value: 'Other' },
]

exports.maritalStatusOptions = [
  { label: 'Married', value: 'Married' },
  { label: 'Single', value: 'Single' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Widowed', value: 'Widowed' },
]

exports.reasonForMeditationOptions = [
  { label: 'Sleep better', value: 'Sleep better' },
  { label: 'Relieve stress and anxiety', value: 'Relieve stress and anxiety' },
  { label: 'Cope with depression', value: 'Cope with depression' },
  {
    label: 'Spirituality or enlightment',
    value: 'Spirituality or enlightment',
  },
  { label: 'Manage emotions', value: 'Manage emotions' },
  { label: 'Break bad habits', value: 'Break bad habits' },
  {
    label: 'Be successful and achieve goals',
    value: 'Be successful and achieve goals',
  },
  {
    label: 'Improve focus and concentration',
    value: 'Improve focus and concentration',
  },
  { label: 'Have peace and calmness', value: 'Have peace and calmness' },
  { label: 'Be happy', value: 'Be happy' },
  { label: 'Improve relationships', value: 'Improve relationships' },
  { label: 'Become healthier', value: 'Become healthier' },
  { label: 'Have gratitude', value: 'Have gratitude' },
  { label: 'Live eternally', value: 'Live eternally' },
  { label: 'Overcome addiction', value: 'Overcome addiction' },
  { label: 'Heal from grief and loss', value: 'Heal from grief and loss' },
]

exports.marketingChannelOptions = [
  { label: 'Google', value: 'Google' },
  { label: 'Facebook', value: 'Facebook' },
  { label: 'Instagram', value: 'Instagram' },
  { label: 'YouTube', value: 'YouTube' },
  { label: 'Eventbrite', value: 'Eventbrite' },
  { label: 'Meetup', value: 'Meetup' },
  { label: 'Groupon', value: 'Groupon' },
  { label: 'Email', value: 'Email' },
  { label: 'Text messaging', value: 'Text messaging' },
  { label: 'Word of mouth', value: 'Word of mouth' },
  { label: 'Brochures', value: 'Brochures' },
  { label: 'Founder WM books', value: 'Founder WM books' },
  { label: 'Walk-in', value: 'Walk-in' },
  { label: 'OME', value: 'OME' },
  { label: 'Unknown', value: 'Unknown' },
]

exports.religionOptions = [
  { label: 'No religion', value: 'No religion' },
  { label: 'Christianity', value: 'Christianity' },
  { label: 'Islam', value: 'Islam' },
  { label: 'Hinduism', value: 'Hinduism' },
  { label: 'Buddhism', value: 'Buddhism' },
  { label: 'Judaism', value: 'Judaism' },
  { label: 'Other', value: 'Other' },
]

exports.reasonForStoppingOptions = [
  {
    label: 'Getting bored (Too repetitive)',
    value: 'Getting bored (Too repetitive)',
  },
  { label: 'Bad review', value: 'Bad review' },
  {
    label: 'Taking too long time to get to the completion level',
    value: 'Taking too long time to get to the completion level',
  },
  { label: 'Lack of result', value: 'Lack of result' },
  { label: 'Lack of time', value: 'Lack of time' },
  { label: 'Lack of money', value: 'Lack of money' },
  { label: 'Lack of motivation', value: 'Lack of motivation' },
  {
    label: 'Opposition from family or friend or acquaintance',
    value: 'Opposition from family or friend or acquaintance',
  },
  { label: 'Religious conflict', value: 'Religious conflict' },
  { label: 'Trouble with helper', value: 'Trouble with helper' },
  { label: 'Taking a break', value: 'Taking a break' },
  {
    label: 'Transferred to other center',
    value: 'Transferred to other center',
  },
  { label: 'Unknown', value: 'Unknown' },
]

exports.billingCycleLengthInMonthOptions = [
  { label: '1 month', value: 1 },
  { label: '2 months', value: 2 },
  { label: '3 months', value: 3 },
  { label: '6 months', value: 6 },
  { label: '1 year', value: 12 },
  { label: '2 years', value: 24 },
]

exports.getNextPaymentDate = (student) => {
  const {
    billingCycleAnchorDate,
    billingCycleLengthInMonth,
    isOneTimePaymentMembership,
  } = student
  if (!billingCycleAnchorDate || !billingCycleLengthInMonth) {
    return null
  }

  const billingIntervalInMonth = isOneTimePaymentMembership
    ? billingCycleLengthInMonth
    : 1

  const today = DateTime.now().startOf('day')
  const diffInMonth = today
    .diff(DateTime.fromISO(billingCycleAnchorDate), 'months')
    .toObject().months

  let times = Math.ceil(diffInMonth / billingIntervalInMonth)
  if (times < 1) {
    // Minimum value of times is 1.
    times = 1
  }

  const nextPaymentDate = DateTime.fromISO(billingCycleAnchorDate).plus({
    months: billingIntervalInMonth * times,
  })

  return nextPaymentDate
}

exports.getNextPaymentDateInMdyy = (student) => {
  return this.getNextPaymentDate(student)
    ? this.getNextPaymentDate(student).toFormat('M/d/yy')
    : ''
}

exports.getMembershipEndDate = (student) => {
  const {
    billingCycleAnchorDate,
    billingCycleLengthInMonth,
    isAutoRenewalMembership,
  } = student
  if (!billingCycleAnchorDate || !billingCycleLengthInMonth) {
    return ''
  }

  if (isAutoRenewalMembership) {
    return ''
  }

  const membershipEndDate = DateTime.fromISO(billingCycleAnchorDate)
    .plus({
      months: billingCycleLengthInMonth,
    })
    .toFormat('M/d/yy')

  return membershipEndDate
}

// exports.sleep = (ms) => {
//   return new Promise((resolve) => setTimeout(resolve, ms))
// }

// @param timezone e.g. 'America/New_York'
exports.displayTimezone = (timezone) => {
  if (!timezone) return ''

  const now = DateTime.now().setZone(timezone)
  return `${now.offsetNameLong} (${now.offsetNameShort})`
}

exports.downloadToExcel = (jsonData, sheetName, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

  XLSX.writeFile(workbook, fileName)
}

exports.displayPhone = (phone) => {
  const areaCode = phone.slice(0, 3)
  const middle = phone.slice(3, 6)
  const last = phone.slice(6)

  return `(${areaCode}) ${middle}-${last}`
}

exports.sendEventToFlutter = (message) => {
  // Check if we are in a WebView environment
  if (window.FlutterWebviewChannel) {
    window.FlutterWebviewChannel.postMessage(message)
  } else {
    console.log('FlutterWebviewChannel not available')
  }
}
