import http from '../http-common'

const BASE_API_URL = '/feedback'

class FeedbackService {
  create(data) {
    return http.post(`${BASE_API_URL}/create`, data)
  }
}

export default new FeedbackService()
