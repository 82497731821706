const initialState = {
  visible: false,
  student: null,
  date: null,
}

export const attendanceDialog = {
  namespaced: true,
  state: initialState,
  mutations: {
    open(state, { student, date }) {
      state.visible = true
      state.student = student
      state.date = date
    },
    close(state) {
      state.visible = false
    }
  }
};