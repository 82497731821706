<template>
  <el-dialog
    v-model="$store.state.bookingDialog.visible"
    @open="handleOpenBookingDialog"
    :title="`${booking ? $t('Update') : $t('Add')} ${$t(
      'booking_for'
    )} ${utils.getPersonName(this.student?.person)}`"
    width="200px"
    style="width: 95%; max-width: 500px"
  >
    <el-form
      v-loading="isLoading"
      :model="form"
      label-position="right"
      label-width="120px"
    >
      <el-form-item :label="$t('Events')">
        <el-select
          v-model="form.eventId"
          :placeholder="$t('Select_Event')"
          size="large"
          style="width: 240px"
        >
          <el-option
            v-for="event in events"
            :key="event.id"
            :label="event.name"
            :value="event.id"
          />
        </el-select>
      </el-form-item>

      <div v-if="form.eventId">
        <el-form-item :label="$t('Date')">
          <el-date-picker
            v-model="form.date"
            value-format="YYYY-MM-DD"
            placeholder="yyyy-mm-dd"
            @change="handleBookingDateChange"
            style="margin-right: 15px"
            :clearable="false"
            :editable="false"
            size="large"
          />
          <span v-html="utils.getHumanReadableDateStr(form.date)"></span>
        </el-form-item>

        <el-form-item :label="$t('Time')" v-if="form.date">
          <el-select
            v-model="form.time"
            :placeholder="$t('Select_Time')"
            size="large"
            style="width: 240px"
          >
            <el-option
              v-for="timeSlot in timeSlots"
              :key="timeSlot.id"
              :label="displayTimeSlot(timeSlot, selectedEvent.timezone)"
              :value="timeSlot.time"
            />
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('Is_Recurring')">
          <el-radio-group v-model="form.isRecurring">
            <el-radio :label="true">{{ $t('Recurring') }}</el-radio>
            <el-radio :label="false">{{ $t('One_time') }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item
          :label="$t('Join_Type')"
          v-if="
            selectedEvent &&
            selectedEvent.enableOnline &&
            selectedEvent.enableInPerson
          "
        >
          <el-radio-group v-model="form.isOnline">
            <el-radio :label="true">{{ $t('Online') }}</el-radio>
            <el-radio :label="false">{{ $t('In_person') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <el-row :justify="booking ? 'space-between' : 'end'">
        <el-button type="danger" v-if="booking" @click="deleteBooking">
          {{ $t('Delete') }}
        </el-button>

        <div>
          <el-button @click="$store.commit('bookingDialog/close', false)">
            {{ $t('Cancel') }}
          </el-button>
          <el-button type="primary" @click="saveBooking" :loading="isLoading">
            {{ booking ? $t('Update') : $t('Save') }}
          </el-button>
        </div>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
import { DateTime } from 'luxon'
import EventService from '@/services/EventService'
import BookingService from '@/services/BookingService'
import utils from '@/helper/utils.js'

export default {
  data() {
    return {
      form: {},
      timeSlots: [],
      events: [],
      isLoading: false,
      utils,
    }
  },
  computed: {
    booking() {
      return this.$store.state.bookingDialog.booking
    },
    student() {
      return this.$store.state.bookingDialog.student
    },
    currentProfile() {
      return this.$store.state.auth.profile
    },
    selectedEvent() {
      return this.events.find((event) => event.id === this.form.eventId)
    },
  },
  methods: {
    displayTimeSlot(timeSlot, timezone) {
      return DateTime.fromISO(timeSlot.time, { zone: timezone })
        .setZone(DateTime.local().zoneName)
        .toFormat('h:mm a')
    },
    handleOpenBookingDialog() {
      if (this.booking) {
        this.form = {
          eventId: this.booking.event.id,
          date: this.booking.date,
          time: this.booking.time,
          isRecurring: this.booking.isRecurring,
          isOnline: this.booking.isOnline,
        }

        this.getAvailableTimeSlotsOfEventForDate(this.booking.date)
      } else {
        this.form = {
          eventId: null,
          date: null,
          time: null,
          isRecurring: false,
          isOnline: true,
        }
      }

      this.getEventsForStudent()
    },
    handleBookingDateChange(date) {
      this.getAvailableTimeSlotsOfEventForDate(date)
    },
    async getEventsForStudent() {
      try {
        const resp = await EventService.getEventsForStudent(this.student.id)
        this.events = resp.data
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async getAvailableTimeSlotsOfEventForDate(date) {
      try {
        const resp = await EventService.getAvailableTimeSlotsOfEventForDate(
          this.form.eventId,
          date
        )
        this.timeSlots = resp.data
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async deleteBooking() {
      try {
        await this.$confirm(
          this.$t('Do_you_really_want_to_delete_this_booking')
        )
      } catch (e) {
        return
      }

      this.isLoading = true

      try {
        await BookingService.deleteBookingByHelper(this.booking.id)

        this.$message.success(
          `${this.$t('Booking_for')} ${utils.getPersonName(
            this.student?.person
          )} ${this.$t('deleted_successfully')}`
        )

        this.emitter.emit('booking-saved')

        this.$store.commit('bookingDialog/close', false)
      } catch (e) {
        utils.handleHttpError(e, this)
      }

      this.isLoading = false
    },
    async saveBooking() {
      this.isLoading = true

      try {
        const payload = {
          date: this.form.date,
          time: this.form.time,
          isRecurring: this.form.isRecurring,
          isOnline: this.form.isOnline,
          eventId: this.form.eventId,
          personId: this.student.person.id,
        }

        if (this.booking) {
          await BookingService.updateBookingByHelper(this.booking.id, payload)
        } else {
          await BookingService.createBookingByHelper(payload)
        }

        this.$message.success(this.$t('Saved'))

        this.emitter.emit('booking-saved')

        this.$store.commit('bookingDialog/close', false)
      } catch (e) {
        utils.handleHttpError(e, this)
      }

      this.isLoading = false
    },
  },
}
</script>
