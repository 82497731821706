import { createStore } from 'vuex'
import { auth } from './auth.module'
import { attendanceDialog } from './attendanceDialog.module'
import { bookingDialog } from './bookingDialog.module'

const store = createStore({
  modules: {
    auth,
    attendanceDialog,
    bookingDialog,
  },
})

export default store
