import http from '../http-common'

const BASE_API_URL = '/booking'

class BookingService {
  /* Helper & Admin */
  getBookings(studentId, pageNumber, pageSize) {
    return http.get(
      `${BASE_API_URL}/getBookings/${studentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
  }

  createBookingByHelper(payload) {
    return http.post(`${BASE_API_URL}/createBookingByHelper`, payload)
  }

  updateBookingByHelper(bookingId, payload) {
    return http.post(
      `${BASE_API_URL}/updateBookingByHelper/${bookingId}`,
      payload
    )
  }

  deleteBookingByHelper(bookingId) {
    return http.delete(`${BASE_API_URL}/deleteBookingByHelper/${bookingId}`)
  }

  /* Public */
  createBookingForPublicEvent(data) {
    return http.post(`${BASE_API_URL}/createBookingForPublicEvent`, data)
  }
}

export default new BookingService()
