import http from '../http-common'

const BASE_API_URL = '/event'

class EventService {
  getEventsForStudent(studentId) {
    return http.get(`${BASE_API_URL}/getEventsForStudent/${studentId}`)
  }

  getEventsForHelperAndAdmin() {
    return http.get(`${BASE_API_URL}/getEventsForHelperAndAdmin`)
  }

  getDefaultTemplates() {
    return http.get(`${BASE_API_URL}/getDefaultTemplates`)
  }

  updateEventTemplate(eventId, payload) {
    return http.post(`${BASE_API_URL}/updateEventTemplate/${eventId}`, payload)
  }

  createEvent(data) {
    return http.post(`${BASE_API_URL}/createEvent`, data)
  }

  updateEvent(id, data) {
    return http.post(`${BASE_API_URL}/updateEvent/${id}`, data)
  }

  deleteEvent(id) {
    return http.delete(`${BASE_API_URL}/deleteEvent/${id}`)
  }

  getTimeSlotsForDate(id, date) {
    return http.get(`${BASE_API_URL}/getTimeSlotsForDate/${id}?date=${date}`)
  }

  createOrUpdateDateSpecificSchedule(data) {
    return http.post(`${BASE_API_URL}/createOrUpdateDateSpecificSchedule`, data)
  }

  getWeeklySchedule(id) {
    return http.get(`${BASE_API_URL}/getWeeklySchedule/${id}`)
  }

  getDateSpecificSchedules(id) {
    return http.get(`${BASE_API_URL}/getDateSpecificSchedules/${id}`)
  }

  deleteDateSpecificSchedule(id, date) {
    return http.delete(
      `${BASE_API_URL}/deleteDateSpecificSchedule/${id}?date=${date}`
    )
  }

  getAvailableTimeSlotsOfEventForDate(id, date) {
    return http.get(
      `${BASE_API_URL}/getAvailableTimeSlotsOfEventForDate/${id}?date=${date}`
    )
  }

  /* Public */
  getAvailableTimeSlotsOfPublicEventForDate(id, date) {
    return http.get(
      `${BASE_API_URL}/getAvailableTimeSlotsOfPublicEventForDate/${id}?date=${date}`
    )
  }

  getPublicEvent(id) {
    return http.get(`${BASE_API_URL}/getPublicEvent/${id}`)
  }

  getPublicEventsOfCenter(centerId) {
    return http.get(`${BASE_API_URL}/getPublicEventsOfCenter/${centerId}`)
  }
}

export default new EventService()
