import http from '../http-common'

const BASE_API_URL = '/attendance'

class AttendanceService {
  createOrUpdate(studentId, data) {
    return http.post(`${BASE_API_URL}/createOrUpdate/${studentId}`, data)
  }
  
  create(studentId, data) {
    return http.post(`${BASE_API_URL}/create/${studentId}`, data)
  }

  delete(studentId, data) {
    return http.post(`${BASE_API_URL}/delete/${studentId}`, data)
  }

  getMyCenterAttendancesByDateRange(startDate, endDate) {
    return http.get(`${BASE_API_URL}/getMyCenterAttendancesByDateRange?startDate=${startDate}&endDate=${endDate}`)
  }

  getAttendancesOfStudentInYearMonth(studentId, yearMonth) {
    return http.get(`${BASE_API_URL}/getAttendancesOfStudentInYearMonth/${studentId}?yearMonth=${yearMonth}`)
  }

  searchAttendanceByStudentIdAndDate(studentId, date) {
    return http.get(`${BASE_API_URL}/searchAttendanceByStudentIdAndDate/${studentId}?date=${date}`)
  }

  getAllByStudentId(studentId, pageNumber, pageSize) {
    return http.get(`${BASE_API_URL}/getAllByStudentId/${studentId}?pageNumber=${pageNumber}&pageSize=${pageSize}`)
  }
}

export default new AttendanceService()
