<template>
  <el-dialog
    v-model="$store.state.attendanceDialog.visible"
    @open="handleOpenAttendanceDialog"
    :title="`${isExistingAttendance ? $t('Update') : $t('Add')} ${$t(
      'meditation_for'
    )} ${utils.getPersonName(this.student?.person)}`"
    width="200px"
    style="width: 95%; max-width: 500px"
  >
    <el-form
      v-loading="isLoadingAttendanceDialog"
      :model="attendanceForm"
      label-position="right"
      label-width="120px"
    >
      <el-form-item :label="$t('Date')">
        <el-date-picker
          v-model="attendanceForm.date"
          value-format="YYYY-MM-DD"
          placeholder="yyyy-mm-dd"
          @change="handleAttendanceDateChange"
          style="margin-right: 15px"
          :clearable="false"
          :editable="false"
        />
        <span
          v-html="utils.getHumanReadableDateStr(attendanceForm.date)"
        ></span>
      </el-form-item>

      <el-form-item :label="$t('Attended')">
        <el-radio-group v-model="attendanceForm.attended">
          <el-radio-button :label="true">{{ $t('Yes') }}</el-radio-button>
          <el-radio-button :label="false">{{ $t('No') }}</el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-form-item :label="$t('Cycles')">
        <el-row>
          <el-col>
            <el-input-number
              v-if="!isCyclesUnknown"
              v-model="attendanceForm.cycles"
              :min="0"
              style="margin-right: 15px"
            />
          </el-col>
          <el-col>
            <el-checkbox
              v-model="isCyclesUnknown"
              :label="$t('Cycle_is_unknown')"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('Hours')">
        <el-row>
          <el-col>
            <el-input-number
              v-if="!isHoursUnknown"
              v-model="attendanceForm.hours"
              :min="0"
              style="margin-right: 15px"
            />
          </el-col>
          <el-col>
            <el-checkbox
              v-model="isHoursUnknown"
              :label="$t('Hour_is_unknown')"
            />
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('Meditation_note')">
        <el-input
          v-model="attendanceForm.memo"
          :autosize="{ minRows: 4 }"
          maxlength="250"
          show-word-limit
          type="textarea"
          :placeholder="$t('Please_enter_any_note')"
        />
      </el-form-item>

      <el-alert
        v-if="isExistingAttendance"
        :title="`${$t('Found_existing_record_on')} ${utils.displayDateInMdyy(
          attendanceForm.date
        )}. ${$t('You_can_update_it')}.`"
        type="info"
        show-icon
        :closable="false"
      />
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$store.commit('attendanceDialog/close', false)">
          {{ $t('Cancel') }}
        </el-button>
        <el-button
          type="primary"
          @click="saveAttendance"
          :loading="isLoadingAttendanceDialogButton"
        >
          {{ isExistingAttendance ? $t('Update') : $t('Save') }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import AttendanceService from '@/services/AttendanceService'
import utils from '@/helper/utils.js'

export default {
  data() {
    return {
      attendanceForm: {},
      isExistingAttendance: false,
      isCyclesUnknown: false,
      isHoursUnknown: false,
      isLoadingAttendanceDialog: false,
      isLoadingAttendanceDialogButton: false,
      utils,
    }
  },
  computed: {
    student() {
      return this.$store.state.attendanceDialog.student
    },
    currentProfile() {
      return this.$store.state.auth.profile
    },
  },
  methods: {
    handleOpenAttendanceDialog() {
      this.initAttendanceForm(this.$store.state.attendanceDialog.date)
    },
    handleAttendanceDateChange(date) {
      this.initAttendanceForm(date)
    },
    async initAttendanceForm(date) {
      try {
        this.isLoadingAttendanceDialog = true
        const resp = await AttendanceService.searchAttendanceByStudentIdAndDate(
          this.student.id,
          date
        )
        const attendance = resp.data
        console.log('attendance: ', attendance)

        this.isExistingAttendance = !!attendance

        if (attendance) {
          this.attendanceForm = attendance

          this.isCyclesUnknown = attendance.cycles === null
          this.isHoursUnknown = attendance.hours === null
        } else {
          this.attendanceForm = {
            date,
            attended: true,
            cycles: this.currentProfile?.helper.center.defaultCyclesOnApp,
            hours: 1,
          }

          this.isCyclesUnknown = false
          this.isHoursUnknown = false
        }
      } catch (e) {
        utils.handleHttpError(e, this)
      } finally {
        this.isLoadingAttendanceDialog = false
      }
    },
    async saveAttendance() {
      try {
        this.isLoadingAttendanceDialogButton = true

        await AttendanceService.createOrUpdate(this.student.id, {
          date: this.attendanceForm.date,
          attended: this.attendanceForm.attended,
          cycles: this.isCyclesUnknown ? null : this.attendanceForm.cycles,
          hours: this.isHoursUnknown ? null : this.attendanceForm.hours,
          memo: this.attendanceForm.memo,
        })

        this.$message.success(this.$t('Saved'))

        this.emitter.emit('attendance-saved')

        this.$store.commit('attendanceDialog/close', false)
      } catch (e) {
        utils.handleHttpError(e, this)
      } finally {
        this.isLoadingAttendanceDialogButton = false
      }
    },
  },
}
</script>
