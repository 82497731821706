import { getEnv, getUser } from '@/helper/utils'
import emitter from '@/helper/eventEmitter'

class WebSocketService {
  constructor() {
    this.ws = null
  }

  connect() {
    const user = getUser()

    this.ws = new WebSocket(
      `${getEnv('WEBSOCKET_URL')}?token=${user?.accessToken}`
    )

    this.ws.onopen = () => {
      console.log('Connected to WebSocket: ', new Date().toLocaleString())
    }

    this.ws.onmessage = (event) => {
      console.log('Received message from WebSocket:', event)
      emitter.emit('webSocketMessage', JSON.parse(event.data))
    }

    this.ws.onclose = () => {
      console.log('Disconnected from WebSocket: ', new Date().toLocaleString())
    }

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error)
    }
  }

  disconnect() {
    if (this.ws) {
      this.ws.close()
    }
  }
}

export default new WebSocketService()
