const initialState = {
  visible: false,
  student: null,
  booking: null,
}

export const bookingDialog = {
  namespaced: true,
  state: initialState,
  mutations: {
    open(state, { student, booking }) {
      state.visible = true
      state.student = student
      state.booking = booking
    },
    close(state) {
      state.visible = false
    },
  },
}
