<template>
  <Navbar v-if="!PUBLIC_PAGES.includes($route.name)" />

  <div
    class="page-wrapper"
    :style="{
      'margin-top': PUBLIC_PAGES.includes($route.name) ? '0px' : '85px',
    }"
  >
    <router-view></router-view>
  </div>

  <div>
    <AttendanceDialog />
    <BookingDialog />
    <el-dialog
      v-model="sendFeedbackDialogVisible"
      :title="$t('Send_Feedback')"
      :width="DEFAULT_DIALOG_WIDTH"
    >
      <el-form :model="form" label-position="top" @submit.prevent>
        <el-form-item :label="$t('Content')">
          <el-input
            v-model="form.content"
            :autosize="{ minRows: 4 }"
            maxlength="1000"
            show-word-limit
            type="textarea"
          />
        </el-form-item>

        <el-form-item :label="$t('Name')">
          <el-input v-model="form.name" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="sendFeedback" style="margin: auto">
            {{ $t('Submit') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import FeedbackService from '@/services/FeedbackService'
import { isSignedIn } from '@/helper/utils'
import { DEFAULT_DIALOG_WIDTH, PUBLIC_PAGES } from '@/helper/constants'
import utils from '@/helper/utils.js'

import AttendanceDialog from '@/components/AttendanceDialog.vue'
import BookingDialog from '@/components/BookingDialog.vue'
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'App',
  components: {
    AttendanceDialog,
    BookingDialog,
    Navbar,
  },
  data() {
    return {
      form: {},
      sendFeedbackDialogVisible: false,
      DEFAULT_DIALOG_WIDTH,
      PUBLIC_PAGES,
    }
  },
  methods: {
    async sendFeedback() {
      try {
        const resp = await FeedbackService.create(this.form)
        console.log('feedback: ', resp.data)
        this.$message.success(this.$t('Feedback_submitted_successfully'))

        this.form = {}
        this.sendFeedbackDialogVisible = false
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
  },
  created() {
    // Removing service workers programmatically. https://stackoverflow.com/questions/33704791/how-do-i-uninstall-a-service-worker
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      console.log('serviceWorker: ', registrations)
      for (const registration of registrations) {
        registration.unregister()
      }
    })

    if (isSignedIn()) {
      this.$store.dispatch('auth/initProfile')
    }
  },
}
</script>

<style scoped></style>
